import utm from '@segment/utm-params';

const getUTMParams = () => {
  const utms = utm(window.location.search);
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: utms.source,
    utm_medium: utms.medium,
    utm_campaign: utms.campaign || utms.name,
    utm_term: utms.term,
    utm_content: utms.content,
    gclid: params.get('gclid'),
  };
};

export default getUTMParams;
