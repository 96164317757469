/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import {
  PaymentRequestButtonElement,
  CardElement,
} from 'react-stripe-elements';

import Loader from './Loader';
import AmountInputs from './AmountInputs';
import PoweredByStripe from './PoweredByStripe';
import EmailField from './EmailField';

const cardElementStyle = {
  base: {
    fontSize: '18px',
    color: '#424770',
    letterSpacing: '0.025em',
    fontFamily: 'Source Code Pro, monospace',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#e01b1b',
  },
};

class StripeForm extends Component {
  constructor(props) {
    super(props);

    this.payButton = React.createRef();
    this.nameFieldRef = React.createRef();
    this.state = {
      loading: false,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const name = this.nameFieldRef.current.value;

    this.setState({ loading: true });
    try {
      await this.props.onSubmit({
        name,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  renderPaymentRequestButton = () => (
    <Fragment>
      <PaymentRequestButtonElement
        paymentRequest={this.props.paymentRequest}
        className="PaymentRequestButton"
        style={{
          paymentRequestButton: {
            theme: 'dark',
            height: '64px',
          },
        }}
        onClick={(event) => {
          event.preventDefault();
          this.payButton.current.click();
        }}
        type="submit"
      />
      <button ref={this.payButton} style={{ display: 'none' }} type="submit">Pay</button>
      <button type="button" className="stripe-link" onClick={this.props.changePaymentMethod}>
        Click here to pay by typing your Credit Card number
      </button>
    </Fragment>
  )

  renderCreditCardForm = () => {
    const hasApplePay = !!window.ApplePaySession;
    const isIframe = window.location !== window.parent.location;

    const showApplePayButton = isIframe && hasApplePay;

    const newWindowUrl = `${window.location.href}&embed=false`;

    return (
      <Fragment>
        <label>
          Card details
          <CardElement
            style={cardElementStyle}
          />
        </label>
        <div className="center-flex">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={this.state.loading}
            style={{
              minWidth: '226px',
            }}
          >
            {
              this.state.loading
                ? <CircularProgress size={27.5} />
                : 'Pay with Credit Card'
            }
          </Button>
          {showApplePayButton
            && (
            <a href={newWindowUrl} target="_blank" rel="noopener noreferrer" className="apple-pay-button">Apple Pay Button</a>
            )}
        </div>
        <PoweredByStripe />
      </Fragment>
    );
  }

  renderProcessingPayment = () => (
    <Loader
      size={8}
      text="Processing payment..."
    />
  )

  renderNameField = () => (
    <label htmlFor="name">
      Name on card
      <input
        id="name"
        name="name"
        type="text"
        placeholder="Name as written on your card"
        required
        ref={this.nameFieldRef}
      />
    </label>
  )

  render() {
    const {
      isProcessingPayment,
      isPaymentRequestApiLoading,
      usePaymentRequestApi,
    } = this.props;

    const showPaymentRequestApiButton = !isPaymentRequestApiLoading && usePaymentRequestApi;
    const showCreditCardInput = !isPaymentRequestApiLoading && !usePaymentRequestApi;

    return (
      <Fragment>
        {isProcessingPayment && this.renderProcessingPayment()}
        <form
          onSubmit={this.handleSubmit}
          style={{ display: isProcessingPayment ? 'none' : 'block' }}
        >
          <AmountInputs />
          <EmailField />
          {isPaymentRequestApiLoading && <Loader />}
          {!isPaymentRequestApiLoading && this.renderNameField()}
          {showPaymentRequestApiButton && this.renderPaymentRequestButton()}
          {showCreditCardInput && this.renderCreditCardForm()}
        </form>
      </Fragment>
    );
  }
}

StripeForm.propTypes = {
  isProcessingPayment: PropTypes.bool,
  isPaymentRequestApiLoading: PropTypes.bool,
  usePaymentRequestApi: PropTypes.bool,
  paymentRequest: PropTypes.object,
  onSubmit: PropTypes.func,
  changePaymentMethod: PropTypes.func,
};

export default StripeForm;
