import React from 'react';
import { Box, Button } from '@material-ui/core';
import { usePaymentContext } from './PaymentContext';
import StripePaymentMethod from './StripePaymentMethod';
import StripeCheckoutPaymentMethod from './StripeCheckoutPaymentMethod';
import PayPalPaymentMethod from './PayPalPaymentMethod';

const STRIPE_PROVIDER = 'stripe';
const STRIPE_CHECKOUT_PROVIDER = 'stripe-checkout';
const PAYPAL_PROVIDER = 'paypal';

const paymentProviders = [
  {
    provider: STRIPE_PROVIDER,
    text: 'Credit Card',
    id: 'select-stripe',
  },
  {
    provider: STRIPE_CHECKOUT_PROVIDER,
    text: 'Stripe Checkout',
    id: 'select-stripe-checkout',
  },
  {
    provider: PAYPAL_PROVIDER,
    text: 'PayPal',
    id: 'select-paypal',
  },
];
const paymentProviderMap = {
  [STRIPE_PROVIDER]: (
    <StripePaymentMethod />
  ),
  [STRIPE_CHECKOUT_PROVIDER]: (
    <StripeCheckoutPaymentMethod />
  ),
  [PAYPAL_PROVIDER]: (
    <PayPalPaymentMethod />
  ),
};

export default function PaymentProviderSelect() {
  const {
    provider: selectedProvider,
    setProvider,
    isProcessingPayment,
    isPaymentSuccess,
  } = usePaymentContext();
  const hideProviderSelectButtons = isProcessingPayment || isPaymentSuccess;

  return (
    <Box className="payment-provider">
      <Box display={hideProviderSelectButtons ? 'none' : 'flex'} justifyContent="center" pb={3}>
        {paymentProviders.map(({ id, provider, text }) => {
          const isSelected = provider === selectedProvider;
          return (
            <Box px={1} key={provider}>
              <Button
                id={id}
                color="primary"
                variant={isSelected ? 'contained' : 'outlined'}
                onClick={() => setProvider(provider)}
                disableElevation
                style={{ pointerEvents: isSelected ? 'none' : '' }}
              >
                {text}
              </Button>
            </Box>
          );
        })}
      </Box>
      { paymentProviderMap[selectedProvider] }
    </Box>
  );
}
