import React from 'react';
import PropTypes from 'prop-types';

import './styles/ErrorPanel.css';

const ErrorPanel = ({ message }) => {
  if (!message) {
    return null;
  }
  let errorMessage = message;
  if (message === 'Network Error') {
    errorMessage = (
      <span>
        Network Error.
        Please check your network connection and try again. If the problem persists, please contact
        {' '}
        <a href="mailto:support@kassellabs.io">support@kassellabs.io</a>
      </span>
    );
  }

  return (
    <div className="error-panel">
      {errorMessage}
    </div>
  );
};

ErrorPanel.propTypes = {
  message: PropTypes.string,
};

export default ErrorPanel;
