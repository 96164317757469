import { getCurrencyExchanges } from './api';

export const defaultCurrencyRates = {
  AED: 3.6730420647039415,
  AFN: 77.25041404945468,
  ALL: 104.65041728023071,
  AMD: 533.1704744479623,
  ANG: 1.7897164398888614,
  AOA: 629.9804869629685,
  ARS: 91.78483711787571,
  AUD: 1.3143638601992198,
  AWG: 1.8000005101225311,
  AZN: 1.703969943580448,
  BAM: 1.6612140236084707,
  BBD: 2.0128253306444206,
  BDT: 84.53137423609151,
  BGN: 1.6627452414069859,
  BHD: 0.3769950042000088,
  BIF: 1967.0002627131037,
  BMD: 1,
  BND: 1.3425651341452216,
  BOB: 6.8935331766690355,
  BRL: 5.708041571585494,
  BSD: 0.9969001554173311,
  BTC: 0.00001700716552115818,
  BTN: 73.21995293269443,
  BWP: 10.99280642210259,
  BYN: 2.629719908722075,
  BYR: 19600.002621179607,
  BZD: 2.0094279145850833,
  CAD: 1.2577708665621483,
  CDF: 1998.000629151122,
  CHF: 0.9429045356694677,
  CLF: 0.025988192363805784,
  CLP: 717.1040080327294,
  CNY: 6.567505364788622,
  COP: 3661.700490057712,
  CRC: 612.080105391315,
  CUC: 1,
  CUP: 26.500003400816876,
  CVE: 94.05040690773923,
  CZK: 22.17440749267974,
  DJF: 177.72041714419802,
  DKK: 6.323811329481341,
  DOP: 56.960400888293364,
  DZD: 134.10390175720207,
  EGP: 15.72880100800212,
  ERN: 15.000359636384657,
  ETB: 41.403881862423354,
  EUR: 0.8502042190534166,
  FJD: 2.0755040860814766,
  FKP: 0.7232610773107699,
  GBP: 0.7233503487537706,
  GEL: 3.4250408948229363,
  GGP: 0.7232610773107699,
  GHS: 5.765040962839273,
  GIP: 0.7232610773107699,
  GMD: 51.25039704537029,
  GNF: 9990.00169105619,
  GTQ: 7.688538737004628,
  GYD: 208.5616279030223,
  HKD: 7.775971188279424,
  HNL: 24.130391570055124,
  HRK: 6.428204504722034,
  HTG: 79.76401901736797,
  HUF: 307.19954548082444,
  IDR: 14537.00194441705,
  ILS: 3.331510778889089,
  IMP: 0.7232610773107699,
  INR: 73.37641346451417,
  IQD: 1462.5001955469702,
  IRR: 42105.0059837373,
  ISK: 126.43040313283251,
  JEP: 0.7232610773107699,
  JMD: 147.56490118926564,
  JOD: 0.7090397113386635,
  JPY: 110.67039962999112,
  KES: 108.90381809710692,
  KGS: 84.79931524552197,
  KHR: 4042.5043394423337,
  KMF: 418.803852785439,
  KPW: 899.9075113842343,
  KRW: 1129.6039425670047,
  KWD: 0.3019908381993355,
  KYD: 0.8307642995847602,
  KZT: 426.2794128149581,
  LAK: 9400.00160603577,
  LBP: 1508.8279067632045,
  LKR: 198.87988399813634,
  LRD: 173.00037153924373,
  LSL: 14.660383374086454,
  LTL: 2.9527405482796967,
  LVL: 0.6048905447088391,
  LYD: 4.5403812995881605,
  MAD: 9.05950494308733,
  MDL: 18.01129921407122,
  MGA: 3790.0008536050354,
  MKD: 52.33363798984516,
  MMK: 1405.8320557597933,
  MNT: 2856.0355886984053,
  MOP: 7.985561831952034,
  MRO: 356.99987587018404,
  MUR: 40.40374923056518,
  MVR: 15.460380483392111,
  MWK: 785.0004497580318,
  MXN: 20.315506534669627,
  MYR: 4.1403780688121286,
  MZN: 67.05038650283798,
  NAD: 14.660379123065361,
  NGN: 381.0003953449618,
  NIO: 35.103729165745605,
  NOK: 8.532005937826264,
  NPR: 117.17199886412715,
  NZD: 1.4220711314857828,
  OMR: 0.38513655980166434,
  PAB: 0.9969001554173311,
  PEN: 3.7430393780586093,
  PGK: 3.530375246134121,
  PHP: 48.56351025516329,
  PKR: 153.55039500488016,
  PLN: 3.908245110475536,
  PYG: 6407.016612140236,
  QAR: 3.6407513084642926,
  RON: 4.18150414729618,
  RSD: 99.86836203055974,
  RUB: 76.48891418718776,
  RWF: 980.0001309314496,
  SAR: 3.750304373110421,
  SBD: 7.987348961220485,
  SCR: 20.80368359479947,
  SDG: 380.50372899570476,
  SEK: 8.727655782919056,
  SGD: 1.3458044122198152,
  SHP: 0.7232610773107699,
  SLL: 10212.505034909384,
  SOS: 586.0004166000673,
  SRD: 14.154040000408095,
  STD: 20604.107003302193,
  SVC: 8.722980509918482,
  SYP: 1257.6448042319764,
  SZL: 14.660371471227387,
  THB: 31.310373851799202,
  TJS: 11.366545144143624,
  TMT: 3.5100001020245064,
  TND: 2.795504630212177,
  TOP: 2.291303771165834,
  TRY: 8.157705230796438,
  TTD: 6.765788292347821,
  TWD: 28.54604195927862,
  TZS: 2319.0006453050023,
  UAH: 27.755515274769,
  UGX: 3651.590924240002,
  USD: 1,
  UYU: 44.23663904069757,
  UZS: 10487.001736967219,
  VEF: 193923570239.20776,
  VND: 23075.003086241315,
  VUV: 109.97334099650735,
  WST: 2.5486197784707887,
  XAF: 557.241717310498,
  XAG: 0.04033113753923692,
  XAU: 0.0005764384605182164,
  XCD: 2.702550952738848,
  XDR: 0.7035516431046738,
  XOF: 556.5036762830432,
  XPF: 101.87505143735525,
  YER: 250.35039721541114,
  ZAR: 14.661305845664128,
  ZMK: 9001.204797192286,
  ZMW: 22.01885922998704,
  ZWL: 322.0003740898564,
};

export const loadCurrencyRates = async () => {
  try {
    const response = await getCurrencyExchanges();
    return response.data.rates;
  } catch (error) {
    console.error('Error on loading currency exchange rates');
    console.error(error);
    return null;
  }
};

export const amountToCurrency = (currencyRates, amount, currency) => {
  const rateTax = amount !== 0 ? 0.1 : 0;
  const currencyUpperCase = currency.toUpperCase();
  if (currencyUpperCase === 'USD') {
    return amount;
  }

  return (amount + rateTax) * currencyRates[currencyUpperCase];
};
