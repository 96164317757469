import * as Sentry from '@sentry/browser';

export const initSentry = () => {
  const isProd = process.env.NODE_ENV === 'production';

  if (isProd) {
    Sentry.init({
      dsn: 'https://c552f5456cdb42609c1923779bfc3bca@sentry.io/1473137',
      release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
      beforeSend: (event, hint) => {
        const error = hint.originalException;
        if (error && error.message
          && (
            error.message.match('Your card was declined.')
            || error.message.match('Your card has insufficient funds.')
            || error.message.match('Your card number is incorrect.')
            || error.message.match('Your card does not support this type of purchase.')
            || error.message.match('Your card\'s security code is incorrect.')
            || error.message.match('Your card has expired.')
            || error.message.match('Your card is not supported for this currency. You can only charge Brazilian cards in BRL in Brazil.')
            || error.message.includes('Extension context invalidated.')
            || error.message.includes('Please load Stripe.js')
          )) {
          return null;
        }
        return event;
      },
    });
  }
};

export const setSentryUser = (email, name) => {
  Sentry.configureScope((scope) => {
    scope.setUser({ email, username: name });
  });
};

export const sentryBreadcrumb = (message) => {
  Sentry.addBreadcrumb({
    message,
  });
};

export const captureException = (exception) => {
  Sentry.captureException(exception);
};
