import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import StripePaymentContainer from './StripePaymentContainer';
import { stripeApiKey } from './lib/config';

import './styles/StripePaymentMethod.css';

const StripePaymentMethod = () => (
  <StripeProvider apiKey={stripeApiKey}>
    <Elements>
      <StripePaymentContainer />
    </Elements>
  </StripeProvider>
);


export default StripePaymentMethod;
