import React from 'react';

import { usePaymentContext } from './PaymentContext';

const EmailField = () => {
  const { email, setEmail } = usePaymentContext();

  return (
    <label htmlFor="email">
      Email
      <input
        id="email"
        name="email"
        type="email"
        placeholder="your.email@example.com"
        required
        value={email}
        onChange={event => setEmail(event.target.value)}
      />
    </label>
  );
};

export default EmailField;
