import React from 'react';
import PropTypes from 'prop-types';
import './styles/SuccessIcon.css';

const SucessIcon = ({ text }) => (
  <div className="icon-container">
    <div className="success-icon">
      <div className="success-icon__tip" />
      <div className="success-icon__long" />
    </div>
    <p>{text}</p>
  </div>
);


SucessIcon.propTypes = {
  text: PropTypes.string,
};


export default SucessIcon;
