// eslint-disable-next-line import/prefer-default-export
export const sendPaymentSuccessMessage = (payload) => {
  if (!window.parent) {
    return;
  }

  window.parent.postMessage({
    type: 'payment',
    action: 'success',
    payload,
  }, '*');
};
