import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './App';

import './styles/index.css';
import './css/main.css';

import { initSentry } from './lib/sentry';

const theme = createMuiTheme({});

initSentry();
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
);
