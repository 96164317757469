import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import queryString from 'query-string';
import axios from 'axios';
import { Box, CircularProgress } from '@material-ui/core';

import ErrorPanel from './ErrorPanel';
import AmountInputs from './AmountInputs';
import EmailField from './EmailField';
import SuccessPage from './SuccessPage';
import { usePaymentContext } from './PaymentContext';
import getApp from './lib/apps';
import Loader from './Loader';
import { validatePaypalPayment } from './lib/api';
import { captureException, sentryBreadcrumb, setSentryUser } from './lib/sentry';
import { paypalApiKey } from './lib/config';
import getUTMParams from './lib/getUTMParams';
import { sendPaymentSuccessMessage } from './windowMessages';

const sleep = (time) => new Promise(resolve => setTimeout(resolve, time))

const getSupportedCurrency = (currency) => {
  if (currency === 'jpy') {
    return 'usd';
  }
  return currency;
};

const getSupportedAmount = (amount, currency, finalAmount) => {
  if (currency === 'jpy') {
    return amount;
  }

  return finalAmount;
};

const isEmailValid = (email) => {
  const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return !!email.match(emailRegex);
};

const PayPalPaymentMethod = () => {
  const {
    app,
    code,
    provider,
    amount,
    email,
    currency,
    finalAmount,
    isProcessingPayment,
    setIsProcessingPayment,
    isPaymentSuccess,
    setIsPaymentSuccess,
  } = usePaymentContext();

  const submitButtonRef = useRef();
  const supportedCurrency = getSupportedCurrency(currency);
  const paypalCurrency = supportedCurrency.toUpperCase();
  const supportedAmount = getSupportedAmount(amount, currency, finalAmount);
  const finalAmountRound = Math.round(supportedAmount * 100) / 100;
  const utms = useMemo(() => getUTMParams(), []);

  const [paymentError, setPaymentError] = useState(null);

  useEffect(() => {
    const { search } = window.location;
    const params = queryString.parse(search);

    const currentCurrency = params.currency || 'usd';
    const shouldReload = currentCurrency !== currency;

    if (!shouldReload) {
      return;
    }

    const newParams = {
      ...params,
      provider,
      currency,
      amount: amount * 100,
      email,
    };

    const newParamsString = queryString.stringify(newParams);
    window.location.search = newParamsString;
  }, [provider, currency, amount, email]);

  const [customId, setCustomId] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const requiredParams = {
      app,
      code,
      email,
      amount: amount * 100,
    };

    const customIdData = {
      ...utms,
      ...requiredParams,
    };

    let cancelled = false;
    let finished = false;
    const controller = new AbortController();
    setLoading(true);
    const initialize = async () => {
      await sleep(500);
      if (cancelled) {
        return
      }

      const response = await axios.request({
        url: `${process.env.REACT_APP_KASSEL_API_URL}/api/custom-ids`,
        method: 'POST',
        data: customIdData,
        signal: controller.signal,
      })

      const newCustomId = response.data
      if (!newCustomId) {
        return
      }

      if (!cancelled) {
        setCustomId(newCustomId.id)
        setLoading(false)
        finished = true
      }
    };
    initialize()

    return () => {
      cancelled = true
      if (finished) {
        controller.abort()
      }
    }
  }, [utms, app, code, email, amount]);

  const appContext = getApp(app);

  if (isPaymentSuccess) {
    return (
      <SuccessPage
        closeFunction={() => setIsPaymentSuccess(false)}
      />
    );
  }

  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
        style={{ display: isProcessingPayment ? 'none' : 'block' }}
      >
        <ErrorPanel message={paymentError} />
        <AmountInputs />
        <EmailField />
        <button ref={submitButtonRef} type="submit" style={{ display: 'none' }} />
        <div style={{ maxWidth: '250px', height: '60px', margin: 'auto' }}>
          {loading ?
            <Box display="flex" justifyContent="center">
              <CircularProgress size={32} />
            </Box>
            : (
            <PayPalButton
              key={customId}
              type="submit"
              createOrder={(data, actions) => actions.order.create({
                application_context: {
                  shipping_preference: 'NO_SHIPPING',
                },
                purchase_units: [{
                  amount: {
                    currency_code: paypalCurrency,
                    value: finalAmountRound,
                  },
                  description: appContext.getPaymentDescription(code),
                  custom_id: customId,
                }],
              })}
              onClick={() => {
                setSentryUser(email);
                sentryBreadcrumb(JSON.stringify({
                  app, code, email, amount, currency, provider,
                }));
                const { minimum } = appContext;
                const centsAmount = Math.round(amount * 100);
                if (centsAmount < minimum) {
                  const minimumParsed = Math.floor(minimum / 100).toFixed(2);
                  setPaymentError(`Amount must be at least US$ ${minimumParsed}`);
                  return false;
                }

                if (!email) {
                  submitButtonRef.current.click();
                  setPaymentError('Email can not be empty.');
                  return false;
                }

                if (!isEmailValid(email)) {
                  submitButtonRef.current.click();
                  setPaymentError('Email is not valid.');
                  return false;
                }

                if (paymentError) {
                  setPaymentError(null);
                }

                setIsProcessingPayment(true);
                return true;
              }}
              onCancel={() => {
                setIsProcessingPayment(false);
                setPaymentError('PayPal Payment canceled');
                sentryBreadcrumb('PayPal Payment canceled');
              }}
              onError={(error) => {
                setIsProcessingPayment(false);
                setPaymentError('There was an error on your PayPal payment, please contact our support or check your PayPal account.');
                captureException(error);
              }}
              onSuccess={async (details, data) => {
                sentryBreadcrumb('PayPal Payment success');

                try {
                  await validatePaypalPayment(data.orderID);
                  setIsPaymentSuccess(true);

                  sendPaymentSuccessMessage({
                    amount,
                    finalAmount: amount,
                    currency: 'USD',
                    email,
                    method: 'paypal',
                  });
                } catch (error) {
                  captureException(error);
                  setPaymentError(`${error.message}. Please contact our support: support@kassellabs.io.`);
                } finally {
                  setIsProcessingPayment(false);
                }
              }}
              style={{
                layout: 'horizontal',
                color: 'gold',
                shape: 'rect',
                label: 'pay',
                height: 40,
              }}
              options={{
                clientId: paypalApiKey,
                currency: paypalCurrency,
              }}
            />
          )}
        </div>
      </form>
      {isProcessingPayment && (
        <Loader
          size={8}
          text="Validating PayPal payment..."
        />
      )}
    </div>
  );
};

export default PayPalPaymentMethod;
