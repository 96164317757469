import React from 'react';

import './css/navbar.css';
import kasselLogo from './assets/logo.svg';

const NavBar = () => (
  <nav className="navBar">
    <div className="content">
      <div className="kasselLogo">
        <a href="https://kassellabs.io">
          <img src={kasselLogo} alt="Kassel Labs" height="20" />
        </a>
      </div>
    </div>
  </nav>
);
export default NavBar;
