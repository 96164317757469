
const parseCode = (code) => {
  try {
    const parsedCode = JSON.parse(code);
    return parsedCode.code
  } catch(error) {
    return code
  }
}

const apps = {
  'star-wars': {
    label: 'Star Wars Intro Creator Payment',
    minimum: 1000,
    getPaymentDescription: (code) => {
      const parsedCode = parseCode(code)
      return `Download link https://starwarsintrocreator.kassellabs.io/#!/${parsedCode}/download/`
    }
  },
  'stranger-things': {
    label: 'Stranger Things Intro Creator Payment',
    minimum: 1000,
    getPaymentDescription: (code) => {
      return `Download link https://strangerthingsintrocreator.kassellabs.io/#!/${code}/download/`
    }
  },
  westworld: {
    label: 'Westworld Intro Creator Payment',
    minimum: 1000,
    getPaymentDescription: (code) => {
      return `Download link https://westworldintrocreator.kassellabs.io/#/${code}/download/`
    }
  },
  'game-of-thrones': {
    label: 'Game of Thrones Intro Creator Payment',
    minimum: 1500,
    getPaymentDescription: (code) => {
      return `Download link https://gameofthronesintrocreator.kassellabs.io/#/${code}/download/`
    }
  },
  'breaking-bad': {
    label: 'Breaking Bad Intro Creator Payment',
    minimum: 1500,
    getPaymentDescription: (code) => {
      return `Download link https://breakingbadintrocreator.kassellabs.io/${code}/download/`
    }
  },
  'the-last-of-us': {
    label: 'The Last of Us Intro Creator Payment',
    minimum: 1000,
    getPaymentDescription: (code) => {
      return `Download link https://thelastofusintrocreator.kassellabs.io/${code}/download/`
    }
  },
  'house-of-the-dragon': {
    label: 'House of the Dragon Intro Creator Payment',
    minimum: 790,
    getPaymentDescription: (code) => {
      return `Download link https://houseofthedragonintrocreator.kassellabs.io/${code}/download/`
    }
  },
  custom: {
    label: 'Custom service payment',
    minimum: 0,
    getPaymentDescription: () => {
      return `Payment for Kassel Labs services.`
    }
  },
  'custom-video': {
    label: 'Custom Video',
    minimum: 0,
    getPaymentDescription: (code) => {
      return `Download Link https://kassellabs.io/purchase-details/?intro=${code}`
    }
  },
  'text-correction': {
    label: 'Video text correction',
    minimum: 500,
    getPaymentDescription: () => {
      return `Payment for video text correction.`
    }
  },
};

const getApp = app => apps[app];

export default getApp;
