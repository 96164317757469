import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { loadCurrencyRates, defaultCurrencyRates, amountToCurrency } from './lib/currencyRates';

const PaymentContext = createContext({});

export const usePaymentContext = () => useContext(PaymentContext);

const STRIPE_PROVIDER = 'stripe';
const INITIAL_CURRENCY = 'usd';

const PaymentContextProvider = ({
  app,
  code: codeFromParams,
  initialAmount,
  initialEmail,
  initialProvider,
  initialCurrency,
  fixedAmount,
  children,
}) => {
  const [code, setCode] = useState(codeFromParams);
  const [currencyRatesIsLoaded, setCurrencyRatesIsLoaded] = useState(false);
  const [currencyRates, setCurrencyRates] = useState(defaultCurrencyRates);
  const [provider, setProvider] = useState(initialProvider || STRIPE_PROVIDER);
  const [email, setEmail] = useState(initialEmail);
  const [amount, setAmount] = useState(initialAmount);
  const [currency, setCurrency] = useState(initialCurrency || INITIAL_CURRENCY);
  const [finalAmount, setFinalAmount] = useState(null);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  useEffect(() => {
    const receiveCodeFromParent = (event) => {
      const { data } = event;
      if (data.action === 'setCode') {
        setCode(data.payload);
      }
    };

    window.addEventListener('message', receiveCodeFromParent);
    return () => {
      window.removeEventListener('message', receiveCodeFromParent);
    };
  }, []);

  useEffect(() => {
    async function loadRates() {
      const rates = await loadCurrencyRates();
      if (rates) {
        setCurrencyRates(rates);
      }
      setCurrencyRatesIsLoaded(true);
    }
    loadRates();
  }, []);

  useEffect(() => {
    const newFinalAmount = amountToCurrency(currencyRates, amount, currency);
    setFinalAmount(newFinalAmount);
  }, [currencyRatesIsLoaded, currencyRates, amount, currency, setFinalAmount]);

  return (
    <PaymentContext.Provider
      value={{
        app,
        code,
        amount,
        setAmount,
        initialEmail,
        email,
        setEmail,
        currency,
        setCurrency,
        finalAmount,
        provider,
        setProvider,
        isProcessingPayment,
        setIsProcessingPayment,
        isPaymentSuccess,
        setIsPaymentSuccess,
        currencyRates,
        fixedAmount,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

PaymentContextProvider.propTypes = {
  app: PropTypes.string,
  code: PropTypes.string,
  initialAmount: PropTypes.number,
  initialEmail: PropTypes.string,
  initialProvider: PropTypes.string,
  initialCurrency: PropTypes.string,
  fixedAmount: PropTypes.bool,
  children: PropTypes.node,
};

export function withPaymentContext(Component) {
  return function WrapperComponent(props) {
    return (
      <PaymentContext.Consumer>
        {state => <Component {...props} paymentContext={state} />}
      </PaymentContext.Consumer>
    );
  };
}

export default PaymentContextProvider;
