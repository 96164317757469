import React, { useState } from 'react';
import { Button, Box, CircularProgress } from '@material-ui/core';

import ErrorPanel from './ErrorPanel';
import AmountInputs from './AmountInputs';
import EmailField from './EmailField';
import SuccessPage from './SuccessPage';
import { usePaymentContext } from './PaymentContext';
import PoweredByStripe from './PoweredByStripe';
import { sendPaymentSuccessMessage } from './windowMessages';
import {
  generateStripeCheckout,
  awaitStripeCheckoutValidation,
} from './lib/api';
import getApp from './lib/apps';
import Loader from './Loader';

const monitorWindowClose = (openedWindow, callback) => {
  const intervalId = setInterval(() => {
    if (openedWindow && openedWindow.closed) {
      callback();
      clearInterval(intervalId);
    }
  }, 500);

  return () => clearInterval(intervalId);
};

const getURLParameter = (parameter) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(parameter);
};

const getCancelURL = ({
  app, code, amount, email,
}) => {
  const cancelURL = getURLParameter('cancelURL');
  if (cancelURL) {
    return cancelURL;
  }

  const baseURL = window.location.origin;
  return `${baseURL}?app=${app}&code=${code}&amount=${amount}&email=${email}`;
};

const getSuccessURL = () => {
  const successURL = getURLParameter('successURL');
  if (successURL) {
    return successURL;
  }

  const baseURL = window.location.origin;
  return `${baseURL}?checkoutSessionId={CHECKOUT_SESSION_ID}`;
};

const StripeCheckoutPaymentMethod = () => {
  const {
    app,
    code,
    amount: amountInputValue,
    // finalAmount,
    email,
    currency,
    isProcessingPayment,
    setIsProcessingPayment,
    isPaymentSuccess,
    setIsPaymentSuccess,
  } = usePaymentContext();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receiptURL, setReceiptURL] = useState(null);

  if (isPaymentSuccess) {
    return (
      <SuccessPage
        receiptURL={receiptURL}
        closeFunction={() => setIsPaymentSuccess(false)}
      />
    );
  }

  return (
    <div>
      <form
        onSubmit={async (event) => {
          event.preventDefault();

          const amount = Math.round(amountInputValue * 100);
          const { minimum } = getApp(app);
          if (amount < minimum) {
            const minimumParsed = Math.floor(minimum / 100).toFixed(2);
            setError(`Amount must be at least US$ ${minimumParsed}`);
            return;
          }

          const paymentData = {
            app,
            code,
            amount,
            currency,
            email,
          };

          setLoading(true);
          const checkout = await generateStripeCheckout({
            ...paymentData,
            cancelURL: getCancelURL(paymentData),
            successURL: getSuccessURL(),
          });
          setLoading(false);

          setIsProcessingPayment(true);
          const openedWindow = window.open(checkout.url);
          const stopMonitoring = monitorWindowClose(openedWindow, () => {
            setIsProcessingPayment(false);
          });

          const newReceiptURL = await awaitStripeCheckoutValidation(checkout.id);

          stopMonitoring();
          setReceiptURL(newReceiptURL);
          setIsPaymentSuccess(true);
          setIsProcessingPayment(false);

          sendPaymentSuccessMessage({
            amount,
            finalAmount: amount / 100,
            currency: 'USD',
            email,
            receiptURL,
            method: 'stripe',
          });
        }}
        style={{ display: isProcessingPayment ? 'none' : 'block' }}
      >
        <ErrorPanel message={error} />
        <AmountInputs />
        <EmailField />
        <Box display="flex" alignItems="center" flexDirection="column">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading}
            style={{
              minWidth: '226px',
            }}
          >
            {
              loading
                ? <CircularProgress size={27.5} />
                : 'Pay with Stripe'
            }
          </Button>
          <PoweredByStripe />
        </Box>
      </form>
      {isProcessingPayment && (
        <Loader
          size={8}
          text="Validating Stripe Checkout payment..."
        />
      )}
    </div>
  );
};

export default StripeCheckoutPaymentMethod;
