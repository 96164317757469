/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, memo } from 'react';
import AmountInput from 'react-currency-input';

import { usePaymentContext } from './PaymentContext';

const currencies = {
  aud: 'AUD $',
  brl: 'BRL R$',
  cad: 'CAD $',
  eur: 'EUR €',
  gbp: 'GBP £',
  jpy: 'JPY ¥',
  usd: 'USD $',
};

const currencyList = Object.entries(currencies);

const currencyOptions = currencyList.map(entry => (
  <option key={entry[0]} value={entry[0]}>{entry[1]}</option>
));

const roundAmount = (value, precision) => {
  const roundValue = Number(`${Math.round(`${value}e${precision}`)}e-${precision}`);
  return roundValue.toFixed(precision); // this grant that 0 returns 0.00
};

const getFinalAmountText = (currency, finalAmount) => {
  if (finalAmount === null) {
    return '';
  }

  const isJPY = currency === 'jpy';
  const precision = isJPY ? 0 : 2;

  const parsedFinalAmount = roundAmount(finalAmount, precision).toLocaleString();
  const currencyPrefix = currencies[currency];
  const textFinalAmount = `${currencyPrefix} ${parsedFinalAmount}`;

  return textFinalAmount;
};

const MemoizedAmountInput = memo(props => (
  <AmountInput
    {...props}
  />
));

const AmountInputs = () => {
  const {
    amount,
    setAmount,
    currency,
    setCurrency,
    finalAmount,
    fixedAmount,
  } = usePaymentContext();

  const handleChangeCurrency = useCallback((event) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);
  }, [setCurrency]);

  const handleChangeAmountInput = useCallback((event, mask, newAmount) => {
    setAmount(newAmount);
  }, [setAmount]);

  const textFinalAmount = getFinalAmountText(currency, finalAmount);

  const receiveAmountFromParent = useCallback((e) => {
    if (!e.origin.match(/https:\/\/[A-Za-z]*\.kassellabs\.io$/)
    && !e.origin.match(/https:\/\/.*-kassellabs\.vercel\.app$/)
    ) return;

    const { data } = e;
    if (data.action === 'setAmount') {
      setAmount(data.payload);
    }
  }, [setAmount]);

  useEffect(() => {
    window.addEventListener('message', receiveAmountFromParent);
    return () => {
      window.removeEventListener('message', receiveAmountFromParent);
    };
  }, [receiveAmountFromParent]);

  return (
    <div className="amount-fields">
      <label htmlFor="amount" className="amount">
        Amount
        <MemoizedAmountInput
          id="amount"
          prefix="USD $ "
          selectAllOnFocus
          onChangeEvent={handleChangeAmountInput}
          value={amount}
          disabled={fixedAmount}
          required
        />
      </label>
      <label htmlFor="currency" className="currency">
        Currency
        <select
          name="currency"
          id="currency"
          defaultValue={currency}
          onChange={handleChangeCurrency}
        >
          {currencyOptions}
        </select>
      </label>
      <label htmlFor="final-amount" className="final-amount">
        Final Amount
        <input
          id="final-amount"
          name="final-amount"
          type="text"
          value={textFinalAmount}
          disabled
        />
      </label>
    </div>
  );
};

export default AmountInputs;
