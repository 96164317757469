import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SuccessPage from './SuccessPage';
import { awaitStripeCheckoutValidation } from './lib/api';
import Loader from './Loader';

const CheckoutSuccess = ({ checkoutSessionId }) => {
  const [receiptURL, setReceiptURL] = useState(null);
  const isLoading = receiptURL === null;

  useEffect(() => {
    awaitStripeCheckoutValidation(checkoutSessionId).then((newReceiptURL) => {
      setReceiptURL(newReceiptURL);
      setTimeout(() => {
        window.close();
      }, 1000);
    });
  }, []);

  if (isLoading) {
    return (
      <Loader
        size={8}
        text="Validating Stripe Checkout payment..."
      />
    );
  }

  return (
    <SuccessPage
      receiptURL={receiptURL}
    />
  );
};

CheckoutSuccess.propTypes = {
  checkoutSessionId: PropTypes.string,
};

export default CheckoutSuccess;
