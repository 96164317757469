
export const stripeApiKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (!stripeApiKey) {
  throw new Error('Missing Stripe API Key');
}

export const paypalApiKey = process.env.REACT_APP_PAYPAL_CLIENT_ID;
if (!paypalApiKey) {
  throw new Error('Missing PayPal API Key');
}

export const apiURL = process.env.REACT_APP_PAYMENT_API_URL;
// export const apiURL = 'https://payment-api.kassellabs.now.sh';
if (!apiURL) {
  throw new Error('Missing Payment API URL');
}
