import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import SuccessIcon from './SuccessIcon';

const SuccessPage = ({ receiptURL, closeFunction }) => (
  <div className="success-page">
    <SuccessIcon
      text="Paid with success!"
    />
    <div className="center-flex">
      {receiptURL && (
        <Button
          component="a"
          color="primary"
          variant="contained"
          href={receiptURL}
          rel="noopener noreferrer"
          target="_blank"
        >
          View Receipt
        </Button>
      )}
      {closeFunction && <button type="button" className="stripe-link margin" onClick={closeFunction}>Pay again</button>}
    </div>
  </div>
);

SuccessPage.propTypes = {
  receiptURL: PropTypes.string,
  closeFunction: PropTypes.func,
};

export default SuccessPage;
