import React from 'react';
import queryString from 'query-string';

import PaymentProviderSelect from './PaymentProviderSelect';
import PaymentContextProvider from './PaymentContext';
import CheckoutSuccess from './CheckoutSuccess';
import NavBar from './NavBar';
import ErrorPanel from './ErrorPanel';
import getApp from './lib/apps';

function App() {
  const { search } = window.location;

  const paramsKeys = ['app', 'code'];
  const params = queryString.parse(search);

  const missingKeys = paramsKeys.filter(key => !(params[key]));

  let error = null;

  const {
    checkoutSessionId,
    app,
    code,
    amount,
    email,
    embed,
    provider,
    currency,
    fixedAmount,
  } = params;

  if (missingKeys.length > 0 && !checkoutSessionId) {
    error = `Error on loading payment: Missing parameters: ${missingKeys.join(', ')}`;
  }

  const isEmbedded = embed === 'true';

  if (!isEmbedded) {
    document.querySelector('body').classList.add('not-embedded');
  }

  const amountNumber = parseInt(amount, 10) / 100;

  const appConfig = getApp(app);

  if (!appConfig && !checkoutSessionId) {
    error = `Invalid app: ${app}`;
  }

  const paymentContent = () => {
    if (checkoutSessionId) {
      return (
        <div className="payment-provider">
          <CheckoutSuccess checkoutSessionId={checkoutSessionId} />
        </div>
      );
    }

    return (
      <PaymentContextProvider
        app={app}
        code={code}
        initialAmount={amountNumber}
        initialEmail={email}
        initialProvider={provider}
        initialCurrency={currency}
        fixedAmount={fixedAmount === 'true'}
      >
        <PaymentProviderSelect />
      </PaymentContextProvider>
    );
  };

  if (isEmbedded) {
    return (
      <div className="App">
        {error && (
          <div className="page-container">
            <ErrorPanel message={error} />
          </div>
        )}
        {!error && paymentContent()}
      </div>
    );
  }

  return (
    <>
      <NavBar />
      <div className="page-container">
        {error && (<ErrorPanel message={error} />)}
        {!error && (
          <div className="payment-container">
            <h1>Payment</h1>
            <div className="content">
              {paymentContent()}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
