import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  injectStripe,
} from 'react-stripe-elements';
import ErrorPanel from './ErrorPanel';
import SuccessPage from './SuccessPage';
import { chargeStripePayment } from './lib/api';
import StripeForm from './StripeForm';
import getApp from './lib/apps';
import { sentryBreadcrumb, setSentryUser } from './lib/sentry';
import { amountToCurrency } from './lib/currencyRates';
import { withPaymentContext } from './PaymentContext';
import { sendPaymentSuccessMessage } from './windowMessages';

const noop = () => {};

const initialState = {
  paymentError: null,
  receiptURL: null,
};

const roundPaymentAmount = (amount, currency) => {
  if (currency === 'jpy') {
    return Math.round(amount);
  }
  return Math.round(amount * 100);
};

const parseAmount = amount => Math.round(amount * 100);

class StripePaymentContainer extends Component {
  constructor(props) {
    super(props);

    const testPaymentRequest = {
      country: 'US',
      currency: 'usd',
      total: {
        label: 'test',
        amount: 100,
      },
    };

    const paymentRequest = props.stripe.paymentRequest(testPaymentRequest);

    paymentRequest.canMakePayment().then((result) => {
      this.setState({ usePaymentRequestApi: !!result, isPaymentRequestApiLoading: false });
    });

    this.state = {
      ...initialState,
      isPaymentRequestApiLoading: true,
      usePaymentRequestApi: false,
      paymentRequest,
    };
  }

  changePaymentMethod = () => {
    this.setState({ usePaymentRequestApi: false });
  }

  createPaymentRequest = async ({
    amount,
    label,
    currency,
    processPayment,
  }) => {
    const { currencyRates } = this.props.paymentContext;
    const convertedAmount = amountToCurrency(currencyRates, amount / 100, currency);
    const finalAmount = roundPaymentAmount(convertedAmount, currency);
    const paymentRequestBody = {
      country: 'US',
      currency,
      total: {
        label,
        amount: finalAmount,
      },
    };

    sentryBreadcrumb('Using Web Payment Request API');
    sentryBreadcrumb(JSON.stringify(paymentRequestBody));

    const paymentRequest = this.props.stripe.paymentRequest(paymentRequestBody);

    paymentRequest.on('token', processPayment);
    const result = await paymentRequest.canMakePayment();

    this.setState({
      paymentRequest,
      usePaymentRequestApi: !!result,
      isPaymentRequestApiLoading: false,
    });

    return paymentRequest;
  }

  handleSubmit = async ({
    name,
  }) => {
    const {
      app, code,
      currency,
      amount: amountInputValue,
      email,
      setIsProcessingPayment,
      setIsPaymentSuccess,
      // finalAmount,
    } = this.props.paymentContext;
    const amount = parseAmount(amountInputValue);

    setSentryUser(email, name);

    const processPayment = async (payload) => {
      const { complete = noop, error, token } = payload;

      if (error) {
        this.setState({ paymentError: error.message });
        setIsProcessingPayment(false);
        return;
      }

      this.setState({ paymentError: null });
      setIsProcessingPayment(true);
      try {
        const paymentData = {
          name,
          email,
          app,
          code,
          token: token.id,
          amount,
          currency,
        };

        sentryBreadcrumb(JSON.stringify(paymentData));
        const result = await chargeStripePayment(paymentData);

        const { receiptURL } = result;

        complete('success');
        this.setState({ receiptURL });
        setIsPaymentSuccess(true);
        setIsProcessingPayment(false);
        sendPaymentSuccessMessage({
          amount,
          finalAmount: amount / 100,
          currency: 'USD',
          email,
          receiptURL,
          method: 'stripe',
        });
      } catch (paymentError) {
        const errorMessage = paymentError.message || 'An error has occurred on this payment.';
        this.setState({
          paymentError: errorMessage,
        });
        setIsPaymentSuccess(false);
        setIsProcessingPayment(false);
        complete('fail');
        throw paymentError;
      }
    };

    if (!this.props.stripe) {
      // eslint-disable-next-line no-console
      console.log("Stripe.js hasn't loaded yet.");
      return;
    }

    const { label, minimum } = getApp(app);

    if (amount < minimum) {
      const minimumParsed = Math.floor(minimum / 100).toFixed(2);
      const paymentError = `Amount must be at least US$ ${minimumParsed}`;
      this.setState({ paymentError });
      return;
    }

    const { usePaymentRequestApi } = this.state;

    if (usePaymentRequestApi) {
      const paymentRequest = await this.createPaymentRequest({
        amount,
        label,
        currency,
        processPayment,
      });
      paymentRequest.show();
      return;
    }

    const payload = await this.props.stripe
      .createToken();

    await processPayment(payload);
  };

  clearState = () => {
    const { setIsPaymentSuccess } = this.props.paymentContext;
    this.setState({ ...initialState });
    setIsPaymentSuccess(false);
  }

  render() {
    const {
      usePaymentRequestApi,
      isPaymentRequestApiLoading,
      paymentError,
      receiptURL,
      paymentRequest,
    } = this.state;

    const { isProcessingPayment, isPaymentSuccess } = this.props.paymentContext;

    if (isPaymentSuccess) {
      return (<SuccessPage receiptURL={receiptURL} closeFunction={this.clearState} />);
    }

    return (
      <Fragment>
        <ErrorPanel message={paymentError} />
        <StripeForm
          isProcessingPayment={isProcessingPayment}
          isPaymentRequestApiLoading={isPaymentRequestApiLoading}
          usePaymentRequestApi={usePaymentRequestApi}
          paymentRequest={paymentRequest}
          onSubmit={this.handleSubmit}
          changePaymentMethod={this.changePaymentMethod}
        />
      </Fragment>
    );
  }
}

StripePaymentContainer.propTypes = {
  stripe: PropTypes.object,
  paymentContext: PropTypes.shape({
    app: PropTypes.string,
    code: PropTypes.string,
    email: PropTypes.string,
    currency: PropTypes.string,
    amount: PropTypes.number,
    isProcessingPayment: PropTypes.bool,
    setIsProcessingPayment: PropTypes.func,
    isPaymentSuccess: PropTypes.bool,
    setIsPaymentSuccess: PropTypes.func,
    currencyRates: PropTypes.object,
    finalAmount: PropTypes.number,
  }),
};

export default withPaymentContext(injectStripe(StripePaymentContainer));
