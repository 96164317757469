import React from 'react';

import './styles/Loader.css';

const Loader = ({
  text,
  style,
  size = 5,
}) => {
  return (<div className="icon-container">
    <div className="loader" style={{
      fontSize: `${size}px`,
      ...style,
    }}>
      Loading...
    </div>
    <p>{text}</p>
  </div>);
};

export default Loader;
