import axios from 'axios';
import get from 'lodash.get';
import { backOff } from 'exponential-backoff';
import { apiURL } from './config';
import getUTMParams from './getUTMParams';

const http = axios.create({ baseURL: apiURL });

const getResponseError = error => get(error, 'response.data.error');

const retryOnUnknowError = (error) => {
  const responseError = getResponseError(error);
  if (responseError) {
    return false;
  }
  return true;
};

// eslint-disable-next-line import/prefer-default-export
export const chargeStripePayment = async ({
  name,
  email,
  app,
  code,
  token,
  amount,
  currency,
}) => {
  try {
    const utms = getUTMParams();
    const resp = await backOff(() => http.post('/stripe/charge', {
      name,
      email,
      app,
      code,
      token,
      amount,
      currency,
      ...utms,
    }), {
      retry: retryOnUnknowError,
    });
    const { receiptURL } = resp.data;
    return { receiptURL };
  } catch (error) {
    const responseError = getResponseError(error);
    if (responseError) {
      console.log({ responseError });
      throw responseError;
    }
    throw error;
  }
};

export const generateStripeCheckout = async ({
  name,
  email,
  app,
  code,
  amount,
  currency,
  successURL,
  cancelURL,
}) => {
  try {
    const utms = getUTMParams();
    const resp = await backOff(() => http.post('/stripe/checkout', {
      ...utms,
      name,
      email,
      app,
      code,
      amount,
      currency,
      successURL,
      cancelURL,
    }), {
      retry: retryOnUnknowError,
    });

    return resp.data;
  } catch (error) {
    const responseError = getResponseError(error);
    if (responseError) {
      console.log({ responseError });
      throw responseError;
    }
    throw error;
  }
};

export const awaitStripeCheckoutValidation = async checkoutId => new Promise((resolve) => {
  let intervalId = null;
  const tryToValidatePayment = async () => {
    const response = await http.get(`/stripe/checkout/${checkoutId}/paid`);
    if (response.data.isPaid) {
      resolve(response.data.receiptURL);
      clearInterval(intervalId);
    }
  };
  tryToValidatePayment();
  intervalId = setInterval(tryToValidatePayment, 2000);
});

export const validatePaypalPayment = async (orderId) => {
  try {
    const resp = await backOff(() => http.post('/paypal/order', {
      orderId,
    }), {
      retry: retryOnUnknowError,
    });
    return resp.data.ok;
  } catch (error) {
    const responseError = getResponseError(error);
    if (responseError) {
      console.log({ responseError });
      throw responseError;
    }
    throw error;
  }
};

export const getCurrencyExchanges = async () => {
  try {
    const resp = await backOff(() => http.get('/exchange-rates'));
    return resp;
  } catch (error) {
    const responseError = getResponseError(error);
    if (responseError) {
      console.log({ responseError });
      throw responseError;
    }
    throw error;
  }
};


window.chargeStripePayment = async (body) => {
  try {
    const result = await chargeStripePayment(body);
    console.log(result);
  } catch (error) {
    console.error('ERROR ON PAYMENT');
    console.error(error);
  }
};
